import React from "react";
import { keyframes, styled } from "styled-components";

const LoadingScreen = () => {
  const BodyDiv = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: #ffffffcb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  const rotate = keyframes`
    from{
        transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }

    to{
      transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  `;

    const fadeIn = keyframes`
    from{
      opacity: 0;
  }

  to{
      opacity: 1;
  }

    `;

  const Rotate = styled.div`
    display: inline-block;
    animation: ${rotate} 4s linear infinite;
    padding: 1rem 1rem;
  `;

  const FadeInDiv = styled.div`
    animation: ${fadeIn} 500ms linear;
  `;

  return (
    <>
      <div>
        <BodyDiv>
          <FadeInDiv>
          <Rotate>
            <img
              src="/images/schoolLogo.png"
              alt="Logo"
              style={{ width: "120px", height: "120px" }}
            />
          </Rotate>
          </FadeInDiv>
        </BodyDiv>
      </div>
    </>
  );
};

export default LoadingScreen;
