import { Suspense, lazy  } from "react";
import LoadingScreen from "./components/LoadingScreen";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HomeProvider } from "./context/HomeContext";

// import StartPage from "./pages/StartPage";

const fakeDelay = (promise) => {
  return new Promise((resolve) => {
    setTimeout(resolve, 2000);
  }).then(() => promise);
}


function App() {
  const StartPage = lazy(() => fakeDelay(import("./pages/StartPage")));
  return (
    <HomeProvider>
      <Router>
        <Routes>
          <Route
            path="/*"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <StartPage />
              </Suspense>
            }
          />
        </Routes>
      </Router>
    </HomeProvider>
  );
}

export default App;
